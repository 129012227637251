<template>
  <div v-if="portfolio">
    <div
      class="list-group-item-heading"
      :class="{
        'text-ellipsis': isPdf || !linkShouldWrap || useEllipsis,
      }"
    >
      <b-link
        v-if="!portfolio.slug.includes('demo_portfolio')"
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
        @click.stop
      >
        <!-- we display a dash when there's no name, so that the user may still click on the hyperlink -->
        {{ portfolioName || '-' }}
      </b-link>
      <span v-else>{{ portfolioName }}</span>
    </div>
    <div
      v-if="shouldShowSecondLine"
      class="text-wrap-xs"
      data-testid="track-subtext"
    >
      {{ numStrategiesSubText }} | <span :style="currencyColor">{{ additionalSubtext }}</span> |
      <span :style="portfolioColor">{{ translate({ path: 'GLOBAL.PORTFOLIO_TYPE_NAME', item: portfolioType }) }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import useFxConversion from '@/composables/useFxConversion';
import { useIsPdf } from '@/composables/usePdf';
import { useRouter } from '@/composables/useRouter';
import useTranslation from '@/composables/useTranslation';
import { FxConversion } from '@/constants/FxConversion';
import { RouteName } from '@/constants/RouteName';
import { SettingsModule } from '@/store/modules/settings';
import { IPortfolioTree } from '@/types/IPortfolioTree';
import { PortfolioType } from '@/types/portfolio';
import { DataTypes, normalizePortfolioType } from '@/types/setting';
import { computed, defineComponent, PropType } from 'vue';

const isPortfolioTree = (item: PortfolioItemResponseDTO | IPortfolioTree): item is IPortfolioTree => {
  return (item as IPortfolioTree).originalSlug !== undefined;
};

export default defineComponent({
  name: 'PortfolioSubtext',
  props: {
    portfolio: {
      type: Object as PropType<PortfolioItemResponseDTO | IPortfolioTree>,
      required: true,
    },
    regressorFxType: {
      type: String as PropType<FxConversion>,
      required: false,
    },
    shouldUseFxCurrency: {
      type: Boolean,
      required: false,
      default: true,
    },
    linkShouldWrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    useEllipsis: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowSecondLine: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const { translate } = useTranslation();
    const { toCurrency } = useFxConversion();

    const router = useRouter();

    const dataTypeColors = SettingsModule.colors.productType || {};

    const portfolioCurrency = computed(() => {
      if (isPortfolioTree(props.portfolio)) {
        return props.portfolio.portfolioTree.toCurrency ?? 'USD';
      }
      return props.portfolio.currency ?? 'USD';
    });

    const getPortfolioType = (item: PortfolioItemResponseDTO | IPortfolioTree) => {
      if (isPortfolioTree(item)) {
        // Currently normalizePortfolioType is used on data page too, so we need to handle the basket case separately
        if (item.portfolioTree.type === PortfolioType.EQUITY_BASKET) return DataTypes.EQUITY_BASKET;
        if (item.portfolioTree.type === PortfolioType.QIS_BASKET) return DataTypes.QIS_BASKET;
        return normalizePortfolioType(item);
      }

      if (item.type === PortfolioType.EQUITY_BASKET) return DataTypes.EQUITY_BASKET;
      if (item.type === PortfolioType.QIS_BASKET) return DataTypes.QIS_BASKET;
      return normalizePortfolioType(props.portfolio);
    };

    const portfolioType = computed(() => getPortfolioType(props.portfolio));

    const url = computed(() => {
      return router.resolve({
        name: RouteName.PORTFOLIO_FACTSHEET,
        params: {
          indexUniqueIdentifier: isPortfolioTree(props.portfolio) ? props.portfolio.originalSlug : props.portfolio.slug,
        },
      }).href;
    });

    const numStrategiesSubText = computed(() => {
      const path =
        portfolioType.value === DataTypes.EQUITY_BASKET
          ? 'GLOBAL.DATABASE_TRANSLATOR.SECURITY'
          : 'GLOBAL.DATABASE_TRANSLATOR.STRATEGY';

      const numStrategies = isPortfolioTree(props.portfolio)
        ? (props.portfolio.portfolioTree.flattenedAllCodes ?? []).length
        : props.portfolio.numStrategies;

      return `${numStrategies} ${translate({
        path,
        pluralIndex: numStrategies,
      })}`;
    });

    const additionalSubtext = computed(() => {
      let subtext = '';
      subtext = `${props.shouldUseFxCurrency ? toCurrency.value : portfolioCurrency.value} `;
      // On the regression page, when fx conversion is applied and it is of type Monthly Hedged, we want to show it
      if (
        props.regressorFxType &&
        toCurrency.value !== portfolioCurrency.value &&
        props.regressorFxType === FxConversion.MONTHLY_HEDGED
      ) {
        subtext = `${subtext} ${translate({
          path: 'GLOBAL.FX_CONVERSION.MONTHLY_HEDGED_SHORT',
        })} `;
      }
      return subtext;
    });

    // TODO: WAA-10221 - Add colors for ProductType - Equity Basket
    /**
     * Each strategy type will have their own individual colors
     */
    const portfolioColor = computed(() => {
      if (!portfolioType.value) return '';
      return `color:${dataTypeColors[portfolioType.value]};`;
    });

    /**
     * On the regression page, when the currency is shown, we want to change the color if a Fx Conversion is applied to the regressor
     */
    const currencyColor = computed(() => {
      if (
        !props.regressorFxType ||
        props.regressorFxType === FxConversion.NOT_CONVERTED ||
        portfolioCurrency.value === toCurrency.value
      )
        return '';
      return `color:${dataTypeColors[DataTypes.THEMATIC]};`;
    });

    const portfolioName = computed(() => {
      if (isPortfolioTree(props.portfolio)) return props.portfolio.portfolioTree.name;
      return props.portfolio.name;
    });

    const isPdf = useIsPdf();

    return {
      url,
      numStrategiesSubText,
      additionalSubtext,
      portfolioColor,
      portfolioType,
      currencyColor,
      translate,
      isPdf,
      portfolioName,
    };
  },
});
</script>
