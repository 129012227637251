import { DateFormat } from '@/constants/DateFormat';
import { ReturnInterval } from '@/constants/ReturnInterval';
import { Period } from '@/types/period';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { convertToBusiness, createDate } from '@/utils/dateUtils';
import { computed, ComputedRef, WritableComputedRef, Ref } from 'vue';
import { DateTime } from 'luxon';
import { notNull } from '@/utils/notnull';
import { PeriodModule } from '@/store/modules/PeriodStore';

export function useAllPeriodsFromToDate(toDate: Ref<DateTime>) {
  const allPeriodsFromToDate = computed(() => {
    const oneMonth = convertToBusiness(toDate.value.minus({ months: 1 }));
    const sixMonths = convertToBusiness(toDate.value.minus({ months: 6 }));
    const oneYear = convertToBusiness(toDate.value.minus({ years: 1 }));
    const threeYears = convertToBusiness(toDate.value.minus({ years: 3 }));
    const fiveYears = convertToBusiness(toDate.value.minus({ years: 5 }));
    const tenYears = convertToBusiness(toDate.value.minus({ years: 10 }));
    // YTD starts from the end of the last year. See https://premialab.atlassian.net/browse/WAA-2517.
    const ytd = convertToBusiness(toDate.value.startOf('year').minus({ days: 1 }));

    const periods: { [key: string]: Period } = {
      [PeriodAbbrevEnum.ONE_M]: new Period({
        fromDate: oneMonth,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.ONE_M,
      }),
      [PeriodAbbrevEnum.SIX_M]: new Period({
        fromDate: sixMonths,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.SIX_M,
      }),
      [PeriodAbbrevEnum.ONE_Y]: new Period({
        fromDate: oneYear,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.ONE_Y,
      }),
      [PeriodAbbrevEnum.THREE_Y]: new Period({
        fromDate: threeYears,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.THREE_Y,
      }),
      [PeriodAbbrevEnum.FIVE_Y]: new Period({
        fromDate: fiveYears,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.FIVE_Y,
      }),
      [PeriodAbbrevEnum.TEN_Y]: new Period({
        fromDate: tenYears,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.TEN_Y,
      }),
      [PeriodAbbrevEnum.YTD]: new Period({
        fromDate: ytd,
        toDate: toDate.value,
        abbrev: PeriodAbbrevEnum.YTD,
      }),
    };
    return periods;
  });
  return { allPeriodsFromToDate };
}

const period = computed((): Period => PeriodModule.currentPeriod);

const returnInterval = computed({
  get() {
    return PeriodModule.currentReturnInterval;
  },
  set(value: ReturnInterval): void {
    PeriodModule.SetReturnInterval(value);
  },
});

const availableReturnIntervals = computed((): ReturnInterval[] => PeriodModule.returnIntervals);

const availablePeriods = computed((): Period[] => PeriodModule.availablePeriods);

const toDate = computed(() => period.value.toDate);

const { allPeriodsFromToDate: allPeriodsWithoutLive } = useAllPeriodsFromToDate(toDate);
const allPeriodsFromToDate = computed(() => {
  if (PeriodModule.periods.live && createDate(PeriodModule.periods.live.fromDate) < toDate.value) {
    allPeriodsWithoutLive.value[PeriodAbbrevEnum.LIVE] = PeriodModule.periods.live;
  }

  if (PeriodModule.periods.max && createDate(PeriodModule.periods.max.fromDate) < toDate.value) {
    allPeriodsWithoutLive.value[PeriodAbbrevEnum.MAX] = PeriodModule.periods.max;
  }

  return allPeriodsWithoutLive.value;
});

/**
 * This computed returns an array of Period[] of all the available periods for a given track. It is used in the risk metrics table on the Factsheets.
 * It follows the logic of availablePeriods in the PeriodStore.ts, however it uses the current period toDate instead of the previous business day.
 * We require to use the period toDate to compute this because we want the risk metrics table data to be consistent with that of the date picker values chosen by the user
 * Otherwise, there is an inconsistency between the metrics in the performance summary and the risk metrics table which in turn might confuse users.
 */
const availablePeriodsFromToDate = computed(() => {
  if (!PeriodModule.disabledBeforeDate) return [];

  const sortedPeriods = Object.values(allPeriodsFromToDate.value).sort((a, b): number =>
    a.fromDate < b.fromDate ? -1 : 1,
  );
  const retval: Period[] = [];
  const disabledBefore = createDate(PeriodModule.disabledBeforeDate);
  sortedPeriods
    .filter((p): boolean => ![PeriodAbbrevEnum.ONE_M, PeriodAbbrevEnum.YTD].includes(p.abbrev))
    .forEach((period: Period): void => {
      if (createDate(period.fromDate) >= disabledBefore) {
        retval.push(period);
      }
    });
  return retval;
});

const periodsObj = computed<{ [period in PeriodAbbrevEnum]?: Period }>(() => PeriodModule.periods);
const sortedPeriods = computed<Period[]>(() => PeriodModule.sortedPeriods);

const previousBusinessDay = computed((): string => PeriodModule.previousBusinessDay.toFormat(DateFormat.YYYY_MM_DD));

const disabledBeforeDate = computed((): string => PeriodModule.disabledBeforeDate);

const disabledAfterDate = computed((): string => PeriodModule.disabledAfterDate);

const endDateHsbcFactsheet = computed(() => {
  return convertToBusiness(createDate(previousBusinessDay.value).minus({ months: 1 }).endOf('month'));
});

const isPeriodValid = computed((): boolean => PeriodModule.isPeriodValid);

export const getTimeInterval = (returnInterval: ReturnInterval): 'days' | 'weeks' | 'months' => {
  if (returnInterval === ReturnInterval.MONTHLY) {
    return 'months';
  }
  if (returnInterval === ReturnInterval.WEEKLY) {
    return 'weeks';
  }
  return 'days';
};

/**
 * We use slightly different logic for rolling graphs. Instead of using the current period, we use the entire track
 * history up until the start of the current period.
 */
export const getRollingPeriod = () => {
  return computed(() => {
    if (!disabledBeforeDate.value) return null;

    return new Period({
      fromDate: createDate(disabledBeforeDate.value),
      toDate: createDate(previousBusinessDay.value),
      abbrev: PeriodAbbrevEnum.CUST,
    });
  });
};

export const getAvailablePeriodsFromIndexMaxDate = (maxDate: Ref<string | null>) => {
  const prevBusinessDay = computed(() => PeriodModule.previousBusinessDay);
  const oneMonth = convertToBusiness(toDate.value.minus({ months: 1 }));
  const sixMonths = convertToBusiness(toDate.value.minus({ months: 6 }));
  const oneYear = convertToBusiness(toDate.value.minus({ years: 1 }));
  const threeYears = convertToBusiness(toDate.value.minus({ years: 3 }));
  const fiveYears = convertToBusiness(toDate.value.minus({ years: 5 }));
  const tenYears = convertToBusiness(toDate.value.minus({ years: 10 }));
  // YTD starts from the end of the last year. See https://premialab.atlassian.net/browse/WAA-2517.
  const ytd = convertToBusiness(prevBusinessDay.value.startOf('year').minus({ days: 1 }));

  const indexPeriods = computed((): { [key in PeriodAbbrevEnum]?: Period } => {
    const retval: { [key in PeriodAbbrevEnum]?: Period } = {
      [PeriodAbbrevEnum.ONE_M]: new Period({
        fromDate: oneMonth,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.ONE_M,
      }),
      [PeriodAbbrevEnum.SIX_M]: new Period({
        fromDate: sixMonths,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.SIX_M,
      }),
      [PeriodAbbrevEnum.ONE_Y]: new Period({
        fromDate: oneYear,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.ONE_Y,
      }),
      [PeriodAbbrevEnum.THREE_Y]: new Period({
        fromDate: threeYears,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.THREE_Y,
      }),
      [PeriodAbbrevEnum.FIVE_Y]: new Period({
        fromDate: fiveYears,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.FIVE_Y,
      }),
      [PeriodAbbrevEnum.TEN_Y]: new Period({
        fromDate: tenYears,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.TEN_Y,
      }),
      [PeriodAbbrevEnum.YTD]: new Period({
        fromDate: ytd,
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.YTD,
      }),
    };

    if (maxDate.value) {
      retval[PeriodAbbrevEnum.MAX] = new Period({
        fromDate: convertToBusiness(createDate(maxDate.value)),
        toDate: prevBusinessDay.value,
        abbrev: PeriodAbbrevEnum.MAX,
      });
    }

    return retval;
  });

  const sortedPeriods = computed((): Period[] => {
    return Object.values(indexPeriods.value).sort((a, b): number => (a.fromDate < b.fromDate ? -1 : 1));
  });

  return computed((): Period[] => {
    if (!maxDate.value) return [];

    return sortedPeriods.value.filter((p) => {
      return (
        !!maxDate.value &&
        ![PeriodAbbrevEnum.ONE_M].includes(p.abbrev) &&
        (p.fromDateString() > maxDate.value || p.abbrev === PeriodAbbrevEnum.MAX)
      );
    });
  });
};

/**
 * Periods for the Risk Metrics table
 */
const riskMetricPeriods = computed(() => {
  const allPeriods = [
    allPeriodsFromToDate.value[PeriodAbbrevEnum.SIX_M],
    allPeriodsFromToDate.value[PeriodAbbrevEnum.ONE_Y],
    allPeriodsFromToDate.value[PeriodAbbrevEnum.THREE_Y],
    allPeriodsFromToDate.value[PeriodAbbrevEnum.FIVE_Y],
    allPeriodsFromToDate.value[PeriodAbbrevEnum.TEN_Y],
  ];

  if (allPeriodsFromToDate.value[PeriodAbbrevEnum.LIVE]) {
    allPeriods.push(allPeriodsFromToDate.value[PeriodAbbrevEnum.LIVE]);
  }

  const maxPeriodObj = allPeriodsFromToDate.value[PeriodAbbrevEnum.MAX];

  const retval = allPeriods
    .filter(notNull)
    .sort((a, b): number => (a.fromDate < b.fromDate ? -1 : 1))
    .filter((period) => availablePeriodsFromToDate.value.includes(period));

  // Keep the max period at the top of the list
  return maxPeriodObj && availablePeriodsFromToDate.value.includes(maxPeriodObj) ? [maxPeriodObj, ...retval] : retval;
});

export default function (): {
  period: ComputedRef<Period>;
  periodsObj: ComputedRef<{ [period in PeriodAbbrevEnum]?: Period }>;
  allPeriodsFromToDate: ComputedRef<{ [period in PeriodAbbrevEnum]?: Period }>;
  sortedPeriods: ComputedRef<Period[]>;
  availablePeriods: ComputedRef<Period[]>;
  availablePeriodsFromToDate: ComputedRef<Period[]>;
  availableReturnIntervals: ComputedRef<ReturnInterval[]>;
  returnInterval: WritableComputedRef<ReturnInterval>;
  disabledBeforeDate: ComputedRef<string>;
  disabledAfterDate: ComputedRef<string>;
  previousBusinessDay: ComputedRef<string>;
  endDateHsbcFactsheet: ComputedRef<DateTime>;
  isPeriodValid: ComputedRef<boolean>;
  getAvailablePeriodsFromIndexMaxDate: (maxDate: Ref<string | null>) => ComputedRef<Period[]>;
  riskMetricPeriods: ComputedRef<Period[]>;
} {
  return {
    period,
    periodsObj,
    allPeriodsFromToDate,
    sortedPeriods,
    availablePeriods,
    availablePeriodsFromToDate,
    availableReturnIntervals,
    returnInterval,
    disabledBeforeDate,
    disabledAfterDate,
    previousBusinessDay,
    isPeriodValid,
    endDateHsbcFactsheet,
    getAvailablePeriodsFromIndexMaxDate,
    riskMetricPeriods,
  };
}
