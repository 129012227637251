import { useQuery } from '@tanstack/vue-query';
import {
  copyPortfolioTree,
  createPortfolioTree,
  getPortfolioTreeRollingTrack,
  getPortfolioTreeCorrelation,
  getPortfolioTreeTrack,
  getRiskMetricsPeriod,
  getPortfolioTreeFrontier,
  getPortfolioTreeReturnRegression,
  getPortfolioTreeExposure,
  appendPortfolioTree,
  createDefaultPortfolioTree,
  getPortfolioTreeMetrics,
  getPortfolioTreeWeights,
  getPortfolioTreeNewDraft,
  getPortfolioTreeKDE,
  submitBasket,
  deletePortfolioTree,
} from '@/api-v2/web/portfolio-trees';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { IPortfolioTreeTrackResponse } from '@/types/IPortfolioTreeTracksResponse';
import { IPortfolioTreeTracksQuery } from '@/types/IPortfolioTreeTracksQuery';
import { computed, ComputedRef, ref, Ref, WritableComputedRef } from 'vue';
import { IPeriodMetricsGenericQuery, IPeriodMetricsQuery } from '@/types/dto/IPeriodMetricsQuery';
import {
  IAppendPortfolioTreeParams,
  IPortfolioTree,
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  ISetPortfolioTreeParams,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { RouteName } from '@/constants/RouteName';
import { ACCEPTED_TRACKED_TITLES } from '@/types/analytics';
import useAppMetrics from '../useAppMetrics';
import { useRouteRef } from '../useRouter';
import { IPortfolioTreeTracksGenericQuery } from '@/types/IPortfolioTreeTracksGenericQuery';
import { IPortfolioTreeRiskMetricsResponse } from '@/types/IPortfolioTreeRiskMetricsResponse';
import {
  IPortfolioTreeRollingTrackQuery,
  IPortfolioTreeRollingTrackResponse,
} from '@/types/IPortfolioTreeRollingTrack';
import { IPortfolioTreeRollingTrackGenericQuery } from '@/types/IPortfolioTreeRollingTrackGenericQuery';
import { useQueriesRef } from './useQueriesRef';
import { IPortfolioTreeCorrelationQuery, IPortfolioTreeCorrelationResponse } from '@/types/IPortfolioTreeCorrelation';
import { IPortfolioTreeCorrelationGenericQuery } from '@/types/IPortfolioTreeCorrelationGenericQuery';
import {
  generateComponents,
  getItemUnderAnalysisPortfolioTreeId,
  isConstituentPortfolioFn,
} from '@/utils/portfolioTree';
import { usePortfolioSidebar } from '@/composables/usePortfolioSidebar';
import { IFrontierLineResponse, IPortfolioTreeFrontierQuery } from '@/types/query/IFrontierLineQuery';
import { IReturnRegressionResponse } from '@/types/IReturnRegressionResponse';
import { CalculateParamConstants } from '@/constants/CalculateParamConstants';
import { IPortfolioTreeExposureQuery } from '@/types/IPortfolioTreeExposureQuery';
import { IPortfolioTreeExposureResponse } from '@/types/IPortfolioTreeExposureResponse';
import { BLMView } from '@/api-v2/web/blm';
import { defineMutation, useManagedMutation, VQMutationOptions } from './defineMutation';
import { chainEnabled, unwrap } from '@/utils/queries';
import { ReturnRegressionPortfolioRequestDTO } from '@/api-v2/web/active-return/types/ReturnRegressionPortfolioRequestDTO';
import { useIndexUniqueIdentifier, usePortfolioTreeDraftIdentifiers } from '../useCorrectIdentifier';
import { convertToBusiness, createDate, enforceMinimumDate, formatDate } from '@/utils/dateUtils';
import { Period } from '@/types/period';
import { ReturnInterval } from '@/constants/ReturnInterval';
import usePortfolioTree from '../usePortfolioTree';
import useFxConversion from '../useFxConversion';
import useAnalysisModes from '../useAnalysisModes';
import useBusinessDates from '../useBusinessDates';
import { IPortfolioTreeMetricsResponse } from '@/types/IPortfolioTreeMetricsResponse';
import { IPortfolioTreeWeightsQuery, IPortfolioTreeWeightsResponseItem } from '@/types/IPortfolioTreeWeights';
import usePeriod from '../usePeriod';
import useRouteChecks from '../useRouteChecks';
import { DateFormat } from '@/constants/DateFormat';
import axios, { AxiosError } from 'axios';
import { useToasts } from '../useToasts';
import useTranslation from '../useTranslation';
import { PortfolioLoadingFailReason, PortfolioModule } from '@/store/modules/PortfolioStore';
import { parseResponseError, KnownError, ErrorCodeConstants } from '@/utils/parseResponseError';
import { useConstituentRiskUtilities } from '@/composables/useConstituentRiskUtilities';
import { useGetRiskDates } from './useRiskData';
import usePremialabQuery from './usePremialabQuery';
import { IPortfolioKdeQuery } from '@/types/IPortfolioKdeQuery';
import { IKdeResponseDto } from '@/types/IKdeResponseDto';
import { useBasketSnapshot } from '../storage/useBasketSnapshot';

export interface AppendPortfolioTreeInput {
  slug: string | null;
  payload: IAppendPortfolioTreeParams;
}

const keys = {
  all: () => [{ scope: 'portfolio-trees' }] as const,
  track: (
    slug: Ref<string | null | undefined>,
    query: Ref<IPortfolioTreeTracksQuery | null>,
    blmView: Ref<BLMView | null>,
    regimeId: Ref<string | undefined>,
  ) => [{ ...keys.all()[0], entity: 'track', slug, query, blmView, regimeId }] as const,
  weight: (
    slug: Ref<string | null>,
    draft: Ref<IPortfolioTree | undefined>,
    query: Ref<IPortfolioTreeWeightsQuery | null>,
  ) =>
    [
      {
        ...keys.all()[0],
        entity: 'weight',
        slug,
        draft,
        query,
        portfolioTreeId: computed(() => query.value?.portfolioTreeId),
      },
    ] as const,
  portfolioAndSubportfolioMetrics: (
    slug: Ref<string | null>,
    query: Ref<IPortfolioTreeTracksQuery | null>,
    blmView: Ref<BLMView | null>,
    regimeId: Ref<string | undefined>,
  ) => [{ ...keys.all()[0], entity: 'portfolioAndSubportfolioMetrics', slug, query, blmView, regimeId }] as const,
  metricsPeriod: (slug: Ref<string | null | undefined>, query: Ref<IPeriodMetricsQuery | null>) =>
    [{ ...keys.all()[0], entity: 'metricsPeriod', slug, query }] as const,
  rollingTrack: (slug: Ref<string | null>, query: Ref<IPortfolioTreeRollingTrackQuery>) =>
    [{ ...keys.all()[0], entity: 'rolling', slug, query }] as const,
  correlation: (slug: Ref<string | null>, query: Ref<IPortfolioTreeCorrelationQuery | null>) =>
    [{ ...keys.all()[0], entity: 'correlation', slug, query }] as const,
  frontier: (slug: Ref<string | null>, query: Ref<IPortfolioTreeFrontierQuery>) =>
    [{ ...keys.all()[0], entity: 'frontier', slug, query, blmId: computed(() => query.value.blmId) }] as const,
  returnRegression: (slug: Ref<string | null>, query: Ref<ReturnRegressionPortfolioRequestDTO | null>) =>
    [{ ...keys.all()[0], entity: 'return-regression-portfolio-tree', slug, query }] as const,
  exposure: (slug: Ref<string | null>, query: Ref<IPortfolioTreeExposureQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'exposure', slug, query }] as const,
  append: (slug: Ref<string | null>, payload: Ref<IAppendPortfolioTreeParams>) =>
    [{ ...keys.all()[0], entity: 'append', slug, payload }] as const,
  draft: (
    slug: Ref<string | null | undefined>,
    params: Ref<{ positionDate?: string; rescalingPage?: CalculateParamConstants } | undefined>,
  ) => [{ ...keys.all()[0], entity: 'draft', slug, params }] as const,
  kde: (slug: Ref<string | null | undefined>, query: Ref<IPortfolioKdeQuery | null>) =>
    [{ ...keys.all()[0], entity: 'kde', slug, query }] as const,
};

/**
 * Key for discovery page scope.
 *
 * Copied from useDataDiscovery. We need to invalidate some of the cache here.
 */
function discoveryAllKey() {
  return [{ scope: 'discover' }] as const;
}

/**
 * Intended for use with components that need the market regime dates,
 * this function generates a computed based on the current itemUnderAnalysis
 * and the currently applied FX conversion and applied regime.
 *
 * You can pass it a different period and returnInterval as desired.
 */
export function getMarketRegimeQuery({
  datasets,
  period,
  returnInterval,
}: {
  datasets: Ref<(IPortfolioTreeStrategy | IPortfolioTreeSubportfolio)[] | undefined>;
  period: ComputedRef<Period>;
  returnInterval: WritableComputedRef<ReturnInterval>;
}) {
  const { fxType, toCurrency } = useFxConversion();
  const { isBlmActive, storeBlmView, isRegimeAnalysisActive, storeRegimeId } = useAnalysisModes();
  const { originalSlug } = usePortfolioTreeDraftIdentifiers();
  const itemUnderAnalysisTreeId = getItemUnderAnalysisPortfolioTreeId({ originalSlug });

  return computed(() => {
    const query: IPortfolioTreeTracksQuery = {
      startDate: period.value.fromDateString(),
      endDate: period.value.toDateString(),
      returnInterval: returnInterval.value,
      codes: [],
      treeIds: [],
      blmId: isBlmActive.value && storeBlmView.value ? storeBlmView.value.id : undefined,
      portfolioTreeId: isBlmActive.value && storeBlmView.value ? itemUnderAnalysisTreeId.value : undefined,
      regimeId: isRegimeAnalysisActive.value && storeRegimeId.value !== null ? storeRegimeId.value : undefined,

      /** TODO: Remove this when we invalidate queries based on draft portfolio API Call.
       * This does not need to be sent to the API as the fx conversion is handled by the draft portfolio
       * But I am adding it to the query so that it can refetch this api based on the changes
       */
      fxType: fxType.value,
      toCurrency: toCurrency.value,
    };

    // TODO: Once API supports portfolioTreeIds for strategies, we will want to remove this and use that instead
    for (const o of datasets.value ?? []) {
      if (isPortfolioTreeStrategy(o) && o.strategy && query.codes && !query.codes.includes(o.strategy.code)) {
        query.codes.push(o.strategy.code);
      }
      if (isPortfolioTreeSubportfolio(o)) {
        query.treeIds.push(o.portfolioTreeId);
      }
    }
    return query;
  });
}

/**
 * *** IMPORTANT ***
 * The slug that we are using has to be string | null, since that is how the composable is set.
 * Need to make sure to set the options: {enabled} is set correctly so the api call is not made unnecessarily.
 *
 * This API call returns identifiers that are either portfolio tree ids (for subportfolios) or codes (for strategies)
 * It returns the codes for strategies so that we may determine on the front end if we should be highlighting
 * a strategy or not.
 *
 * We are passing the storeBlmView to the key because the query contains only the BLMid which remains the same
 * even if the storeBlmView changes, therefore vue-query does not invalidate the cache.
 * The final goal is to migrate the create and mutate BLM view to vue query, and onMutation we will invalidate
 * the cache of the keys that use the BLM data. This is only a temporary fix.
 */
export function usePortfolioTreeTrackData({
  draftSlug,
  query,
  blmView = ref(null),
  regimeId = ref(),
  options = {},
}: {
  draftSlug: Ref<string | null | undefined>;
  query: Ref<IPortfolioTreeTracksQuery | null>;
  options?: VQQueryOptions<IPortfolioTreeTrackResponse[], Error>;
  blmView?: Ref<BLMView | null>;
  regimeId?: Ref<string | undefined>;
}) {
  const areDatesValid = enforceMinimumDate(query);

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!draftSlug.value && !!query.value),
    areDatesValid,
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.track(draftSlug, query, blmView, regimeId),
      queryFn: () => getPortfolioTreeTrack(unwrap(draftSlug), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function usePortfolioTreeTracksData(
  parameters: Ref<Array<IPortfolioTreeTracksGenericQuery>>,
  regimeId: Ref<string | undefined> = ref(),
  options: VQQueryOptions<IPortfolioTreeTrackResponse[]> = {},
) {
  return useQueriesRef({
    queries: computed(() => {
      return parameters.value.map((param) => ({
        ...options,
        queryKey: keys.track(ref(param.slug), ref(param.query), ref(null), regimeId),
        queryFn: () => getPortfolioTreeTrack(unwrap(param.slug), param.query),
        enabled: chainEnabled(
          options.enabled,
          computed(() => parameters.value.every((p) => !!p.slug)),
        ),
        staleTime: Number.POSITIVE_INFINITY,
      }));
    }),
  });
}

/**
 * Simplex's portfolio is using a special FX conversion that is start date dependent, if the portfolio is calculated
 * with a different start date, the entire portfolio track will be different significantly
 * so now we should be forcing all track based analysis to first calculate the entire portfolio track using its full
 * length before doing any further analysis (e.g. PCA, regression)
 *
 * It is a bit opaque to the user what start and end dates are used for the generation of the weights.
 * As of 16 Aug 2024, Ryan confirmed with John that at least on PCA and Factor Decomposition, the weights should
 * ALWAYS be calculated using the WHOLE track length.
 *
 * This functionality will change in the future when we expose controls to the user which will allow them to
 * change on which dates their weights are generated from.
 */
export function usePortfolioTreeWeightsQuery() {
  const { period, returnInterval, previousBusinessDay } = usePeriod();
  const route = useRouteRef();
  const { isOnConstituentRisk, isOnFactorDecomposition, isOnPca } = useRouteChecks(route);
  const { positionDate: storePositionDate } = useConstituentRiskUtilities();
  const { masterPortfolioTree } = usePortfolioTree();
  const { storeBlmViewId, isBlmActive } = useAnalysisModes();

  const { originalSlug } = usePortfolioTreeDraftIdentifiers();
  const itemUnderAnalysisTreeId = getItemUnderAnalysisPortfolioTreeId({ originalSlug });

  return computed((): IPortfolioTreeWeightsQuery | null => {
    if (
      (isOnFactorDecomposition.value || isOnPca.value) &&
      (!masterPortfolioTree.value || !masterPortfolioTree.value?.portfolioTree.historyStartDate)
    )
      return null;

    const positionDate =
      isOnConstituentRisk.value && storePositionDate.value ? formatDate(storePositionDate.value) : undefined;

    const startDate =
      isOnFactorDecomposition.value || isOnPca.value
        ? createDate(masterPortfolioTree.value?.portfolioTree.historyStartDate, { add: true })
        : period.value.fromDate;

    const endDate =
      isOnFactorDecomposition.value || isOnPca.value ? createDate(previousBusinessDay.value) : period.value.toDate;

    return {
      startDate: convertToBusiness(startDate).toFormat(DateFormat.YYYY_MM_DD),
      endDate: convertToBusiness(endDate).toFormat(DateFormat.YYYY_MM_DD),
      positionDate,
      returnInterval: isOnFactorDecomposition.value || isOnPca.value ? ReturnInterval.DAILY : returnInterval.value,
      portfolioTreeId: isBlmActive.value ? itemUnderAnalysisTreeId.value : '',
      blmId: isBlmActive.value ? storeBlmViewId.value : undefined,
    };
  });
}

export function usePortfolioTreeWeights({
  portfolioTreeDraft,
  query,
  options = {},
}: {
  portfolioTreeDraft: Ref<IPortfolioTree | undefined>;
  query: Ref<IPortfolioTreeWeightsQuery | null>;
  options?: VQQueryOptions<IPortfolioTreeWeightsResponseItem, Error>;
}) {
  const portfolioTreeDraftIdentifier = computed(() => portfolioTreeDraft.value?.slug ?? null);
  const isConstituentRiskPortfolio = isConstituentPortfolioFn(computed(() => portfolioTreeDraft.value ?? null));
  const areDatesValid = enforceMinimumDate(query);

  const handleError = (error: AxiosError): void => {
    const { translate } = useTranslation();
    const { errorToast } = useToasts();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((error.response?.data as any)?.message?.includes('Negative track values')) {
      errorToast(
        translate({
          path: 'TOASTS.NAVIGATION_PANEL.PORTFOLIO.NEGATIVE_TRACK_VALUES_MESSAGE',
        }),
      );
    }
  };
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!query.value && !!portfolioTreeDraftIdentifier.value),
    computed(() => isConstituentRiskPortfolio.value || areDatesValid.value),
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.weight(portfolioTreeDraftIdentifier, portfolioTreeDraft, query),
      queryFn: () => getPortfolioTreeWeights({ slug: unwrap(portfolioTreeDraftIdentifier), params: unwrap(query) }),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
      onError: handleError,
    }),
    enabled,
  );
}

export function usePortfolioTreeRollingTracksData(
  parameters: Ref<Array<IPortfolioTreeRollingTrackGenericQuery>>,
  options: VQQueryOptions<IPortfolioTreeRollingTrackResponse> = {},
) {
  return useQueriesRef({
    queries: computed(() => {
      return parameters.value.map((param) => ({
        queryKey: keys.rollingTrack(ref(param.slug), ref(param.query)),
        queryFn: () => getPortfolioTreeRollingTrack({ slug: param.slug, query: param.query }),
        ...options,
        staleTime: Number.POSITIVE_INFINITY,
      }));
    }),
  });
}

/**
 * This composable should be used when you need the metrics for a portfolio and its subportfolios.
 * The other composable, usePortfolioTreeMetricsData, does not support returning metrics for subportfolios.
 */
export function usePortfolioAndSubportfolioMetrics(
  slug: Ref<string | null>,
  query: Ref<IPortfolioTreeTracksQuery | null>,
  options: VQQueryOptions<IPortfolioTreeMetricsResponse[], Error> = {},
  blmView: Ref<BLMView | null> = ref(null),
  regimeId: Ref<string | undefined> = ref(),
) {
  const areDatesValid = enforceMinimumDate(query);

  const queryToUse = computed((): IPortfolioTreeTracksQuery | null => {
    if (!query.value) return null;
    return useBusinessDates<IPortfolioTreeTracksQuery>(query.value);
  });

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value && !!query.value),
    areDatesValid,
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.portfolioAndSubportfolioMetrics(slug, queryToUse, blmView, regimeId),
      queryFn: () => getPortfolioTreeMetrics(unwrap(slug), unwrap(queryToUse)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * @deprecated - should be migrated to use /metrics API instead
 */
export function usePortfolioTreeMetricsData(
  parameters: Ref<Array<IPeriodMetricsGenericQuery>>,
  options: VQQueryOptions<IPortfolioTreeRiskMetricsResponse> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<VQQueryOptions<IPortfolioTreeRiskMetricsResponse>> => {
      return parameters.value.map((param) => ({
        queryKey: keys.metricsPeriod(ref(param.slug), ref(param.query)),
        queryFn: () => getRiskMetricsPeriod(unwrap(param.slug), param.query),
        ...options,
        staleTime: Number.POSITIVE_INFINITY,
        enabled: chainEnabled(
          options.enabled,
          computed(() => parameters.value.every((p) => !!p.slug)),
        ),
      }));
    }),
  });
}

/**
 * @deprecated - should be migrated to use /metrics API instead
 */
export function usePortfolioTreeMetricsDataSingle(
  slug: Ref<string | null | undefined>,
  query: Ref<IPeriodMetricsQuery | null>,
  options: VQQueryOptions<IPortfolioTreeRiskMetricsResponse> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.metricsPeriod(slug, query),
      queryFn: () => getRiskMetricsPeriod(unwrap(slug), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export const useDuplicatePortfolioTree = defineMutation<
  IPortfolioTree,
  Error,
  ISetPortfolioTreeParams & { slug: string }
>({
  mutationFn({ slug, ...rest }) {
    return copyPortfolioTree(slug, rest);
  },
  invalidateCache(client) {
    return Promise.all([client.invalidateQueries(discoveryAllKey()), client.invalidateQueries(keys.all())]);
  },
});

export function useCreatePortfolioTree(
  options: VQMutationOptions<IPortfolioTree, Error, ISetPortfolioTreeParams> = {},
) {
  const route = useRouteRef();
  const { track } = useAppMetrics();

  return useManagedMutation(
    {
      mutationFn: createPortfolioTree,
      invalidateCache(client) {
        // when invalidating, we probably want to invalidate all portfolio tree data
        return Promise.all([client.invalidateQueries(discoveryAllKey()), client.invalidateQueries(keys.all())]);
      },
      onSuccess(data) {
        track(ACCEPTED_TRACKED_TITLES.PORTFOLIO_CREATED, {
          pageName:
            route.value.name === RouteName.STRATEGY_FACTSHEET
              ? 'Strategy Factsheet'
              : route.value.name === RouteName.DATA
              ? 'Discover'
              : 'Analytics',
          portfolioId: data.portfolioId,
        });
      },
    },
    options,
  );
}

export function useCreateDefaultPortfolioTree(options: VQMutationOptions<IPortfolioTree, Error> = {}) {
  const route = useRouteRef();
  const { track } = useAppMetrics();

  return useManagedMutation(
    {
      mutationFn() {
        return createDefaultPortfolioTree();
      },
      invalidateCache(client) {
        // when invalidating, we probably want to invalidate all portfolio tree data
        return Promise.all([client.invalidateQueries(discoveryAllKey()), client.invalidateQueries(keys.all())]);
      },
      onSuccess(data) {
        track(ACCEPTED_TRACKED_TITLES.PORTFOLIO_CREATED, {
          pageName:
            route.value.name === RouteName.STRATEGY_FACTSHEET
              ? 'Strategy Factsheet'
              : route.value.name === RouteName.DATA
              ? 'Discover'
              : 'Analytics',
          portfolioId: data.portfolioId,
        });
      },
    },
    options,
  );
}

export function usePortfolioTreeCorrelationData(
  slug: Ref<string | null>,
  query: Ref<IPortfolioTreeCorrelationQuery | null>,
  options: VQQueryOptions<IPortfolioTreeCorrelationResponse, Error> = {},
) {
  const areDatesValid = enforceMinimumDate(query);
  const enabled = chainEnabled(
    options.enabled,
    areDatesValid,
    computed(() => !!slug.value && !!query.value),
  );
  return usePremialabQuery(
    useQuery({
      queryKey: keys.correlation(slug, query),
      queryFn: () => getPortfolioTreeCorrelation(unwrap(slug), unwrap(query)),
      ...options,
      enabled,
    }),
    enabled,
  );
}

export function usePortfolioTreeCorrelationsData(
  parameters: Ref<Array<IPortfolioTreeCorrelationGenericQuery>>,
  options: VQQueryOptions<IPortfolioTreeCorrelationResponse> = {},
) {
  return useQueriesRef({
    queries: computed((): Array<VQQueryOptions<IPortfolioTreeCorrelationResponse>> => {
      return parameters.value.map((param) => {
        const areDatesValid = enforceMinimumDate(ref(param.query));
        return {
          queryKey: keys.correlation(ref(param.slug), ref(param.query)),
          queryFn: () => getPortfolioTreeCorrelation(unwrap(param.slug), unwrap(param.query)),
          ...options,
          staleTime: Number.POSITIVE_INFINITY,
          enabled: chainEnabled(options.enabled, areDatesValid),
        };
      });
    }),
  });
}

/**
 * Get the frontier data as vue-query
 *
 * Most of the time you may want to use `useFrontierWrapper` instead.
 */
export function useFrontier(
  slug: Ref<string | null>,
  query: Ref<IPortfolioTreeFrontierQuery>,
  options: VQQueryOptions<IFrontierLineResponse> = {},
) {
  const areDatesValid = enforceMinimumDate(query);
  const enabled = chainEnabled(
    options.enabled,
    areDatesValid,
    computed(() => !!slug.value),
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.frontier(slug, query),
      queryFn: ({ signal }) => getPortfolioTreeFrontier({ slug: unwrap(slug.value), query: query.value }, signal),
      ...options,
      staleTime: Number.POSITIVE_INFINITY,
      enabled,
    }),
    enabled,
  );
}

/**
 * Generate list of query selector that select the given portfolio.
 *
 * Pass the array to `invalidateQueries` to actually invalidate the query.
 */
function invalidatePortfolio(portfolio: IPortfolioTree) {
  return [
    [{ portfolioId: portfolio.portfolioId }], // F it we will have typo anyway why not support both portfolio(tree)?Id
    [{ portfolioTreeId: portfolio.portfolioId }],
    [{ slug: portfolio.slug }],
  ];
}

export const useSavePortfolioTree = defineMutation<IPortfolioTree, Error, string>({
  mutationFn(slug) {
    return PortfolioModule.SavePortfolioTree({ slug });
  },
  invalidateCache(client, draftSlug, data) {
    const selectors: (readonly Record<string, unknown>[])[] = [
      // when invalidating, we probably want to invalidate all portfolio tree data
      keys.all(),
      // Also invalidating non-tree portfolios to make sure data is consistent
      [{ scope: 'portfolios' }],
      [{ slug: draftSlug }],
    ];

    // Invalidate draft portfolio ID
    // This assumes/check the current portfolio under analysis is the one we
    // are saving.
    const tree = PortfolioModule.portfolioTreeUnderAnalysis;
    if (tree && tree.slug === draftSlug) {
      selectors.push(...invalidatePortfolio(tree));
    }

    // Invalidate slug/portfolioId that are using the non-draft one
    if (data) {
      selectors.push(...invalidatePortfolio(data));
    }

    return Promise.all([
      client.invalidateQueries(discoveryAllKey()),
      ...selectors.map((selector) => client.invalidateQueries(selector)),
    ]);
  },
});

export const useDeletePortfolioTree = defineMutation<void, Error, string>({
  mutationFn(slug) {
    return deletePortfolioTree(slug);
  },
  invalidateCache(client) {
    // when invalidating, we probably want to invalidate all portfolio tree data
    return Promise.all([client.invalidateQueries(discoveryAllKey()), client.invalidateQueries(keys.all())]);
  },
});

/**
 * Function to submit a basket and create an official snapshot/request
 * The slug is the originalSlug of master portfolio tree instead of draft slug
 * which means the platform would need to save all of the user's changes before triggering this request.
 */
export const useSubmitBasket = defineMutation({
  mutationFn: ({ originalSlug }: { originalSlug: string }) => submitBasket(originalSlug),
  invalidateCache(client) {
    return client.invalidateQueries(keys.all());
  },
});

export interface UseCalculatePortfolioTreeVariable {
  tree: IPortfolioTree;
  /**
   * Defaults to false because generally we are updating from the NavigationPanel
   * On the off chance we aren't (for example, when we're just updating the FX conversion),
   * turn this variable true in order to just use the existing masterPortfolioTree components
   */
  useExistingComponents?: boolean;
}

/**
 * When calling this mutation, set `useExistingComponents` to true if not triggering this from the NavigationPanel
 */
export function useCalculatePortfolioTree(
  options: VQMutationOptions<IPortfolioTree | null, Error, UseCalculatePortfolioTreeVariable> = {},
) {
  const { sidebarRows } = usePortfolioSidebar();
  const route = useRouteRef();
  const { isOnConstituentRisk } = useRouteChecks(route);

  return useManagedMutation(
    {
      mutationFn(variable) {
        const { tree, useExistingComponents } = variable;
        const components = useExistingComponents
          ? tree.portfolioTree.components
          : generateComponents(sidebarRows.value, tree);

        const payload: ISetPortfolioTreeParams = {
          portfolioTree: {
            ...tree.portfolioTree,
            components,
          },
          allocation: tree.allocation,
          isBenchmark: tree.isBenchmark,
          // We want to allow the api to rescale all the weights when on a portfolio tree page
          // The rescale behavior will be different for a risk page and non risk page
          rescalingPage: isOnConstituentRisk.value ? CalculateParamConstants.RISK : CalculateParamConstants.NON_RISK,
        };

        return PortfolioModule.SetPortfolioTree({
          slug: tree.slug,
          payload,
        });
      },
      updateCache(client, updatedTree, variable) {
        // directly update the cache for the /draft used in the application
        // we are using the originalSlug here because the draft is keyed on the indexUniqueIdentifier
        client.setQueriesData([{ ...keys.all()[0], entity: 'draft', slug: variable.tree.originalSlug }], updatedTree);
      },
      invalidateCache(client, variable) {
        const selectors = invalidatePortfolio(variable.tree);
        return Promise.all(selectors.map((selector) => client.invalidateQueries(selector)));
      },
    },
    options,
  );
}

export function usePortfolioTreeReturnRegressionData(
  slug: Ref<string | null>,
  query: Ref<ReturnRegressionPortfolioRequestDTO | null>,
  options: VQQueryOptions<IReturnRegressionResponse> = {},
) {
  const { draftSlug } = usePortfolioTreeDraftIdentifiers();
  const areDatesValid = enforceMinimumDate(query);

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!draftSlug.value && !!query.value),
    areDatesValid,
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.returnRegression(slug, query),
      queryFn: () => getPortfolioTreeReturnRegression(unwrap(slug), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function usePortfolioTreeTrackExposure(
  slug: Ref<string | null>,
  query: Ref<IPortfolioTreeExposureQuery>,
  options: VQQueryOptions<IPortfolioTreeExposureResponse> = {},
) {
  const { draftSlug } = usePortfolioTreeDraftIdentifiers();
  const areDatesValid = enforceMinimumDate(query);

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!draftSlug.value),
    computed(() => !!slug.value && !!query.value),
    areDatesValid,
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.exposure(slug, query),
      queryFn: () => getPortfolioTreeExposure(unwrap(slug), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * This composable may be able to replace `masterPortfolioTree` at some point in the future
 * Currently, shouldShowBasketSnapshot is only applicable to equity basket portfolios
 * As spa would not know the portfolio type before getting the draft.
 * We would send out the request with shouldShowBasketSnapshot as true for all portfolios
 * and the API would ignore it the shouldShowBasketSnapshot param if it is not an equity basket portfolio.
 */
export function usePortfolioTreeDraft({
  slug,
  force,
  options = {},
}: {
  slug: Ref<string | null | undefined>;
  force?: { positionDate: string; rescalingPage: CalculateParamConstants };
  options?: VQQueryOptions<IPortfolioTree>;
}) {
  const route = useRouteRef();
  const { isOnConstituentRisk } = useRouteChecks(route);
  const { shouldShowBasketSnapshot } = useBasketSnapshot();
  const { positionDate } = useConstituentRiskUtilities();
  const { translate } = useTranslation();

  /**
   * If positionDate is NOT supplied, then the API will always use the LAST available risk date to create the draft
   *
   * This is ONLY true for risk portfolios.
   */
  const params = computed(
    ():
      | { positionDate?: string; rescalingPage?: CalculateParamConstants; shouldShowBasketSnapshot?: boolean }
      | undefined => {
      if (force) return force;

      // TODO: changing the usage of shouldShowBasketSnapshot if shouldShowBasketSnapshot is applicable to risk portfolio
      if (!isOnConstituentRisk.value) {
        return {
          rescalingPage: CalculateParamConstants.NON_RISK,
          /**
           * shouldShowBasketSnapshot is only relevant for equity basket portfolio
           * If it is not an equity basket portfolio, this param should not affect anything.
           */
          shouldShowBasketSnapshot: shouldShowBasketSnapshot.value,
        };
      }

      if (!positionDate.value) return undefined;

      return {
        rescalingPage: CalculateParamConstants.RISK,
        positionDate: positionDate.value.toFormat(DateFormat.YYYY_MM_DD),
      };
    },
  );

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value && !!params.value),
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.draft(slug, params),
      queryFn: () => getPortfolioTreeNewDraft(unwrap(slug), unwrap(params)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
      onError(err) {
        const error = parseResponseError(err);
        let failReason;
        if (error === KnownError.NetworkError) {
          failReason = PortfolioLoadingFailReason.NetworkError;
        } else if (error === ErrorCodeConstants.PORTFOLIO_NOT_FOUND) {
          failReason = PortfolioLoadingFailReason.NotFound;
        } else {
          failReason = PortfolioLoadingFailReason.Other;
        }

        if (
          axios.isAxiosError(err) &&
          err.response?.data &&
          err.response.data.message === 'Specified position date not found'
        ) {
          const { errorToast } = useToasts();
          const { translate } = useTranslation();
          errorToast(translate({ path: 'GLOBAL.ERROR.POSITION_DATE_NOT_FOUND' }));
        }

        PortfolioModule.SetPortfolioTreeFailReason(failReason);
        console.error(translate({ path: 'ERROR.FETCH_PORTFOLIO_TREE' }));
      },
    }),
    enabled,
  );
}

/**
 * Same as above, designed to be used in risk charts. Will not query unless risk dates are available.
 */
export function usePortfolioTreeDraftOnRisk(
  force?: { positionDate: string; rescalingPage: CalculateParamConstants },
  options: VQQueryOptions<IPortfolioTree> = {},
) {
  const slug = useIndexUniqueIdentifier();
  const { positionDate } = useConstituentRiskUtilities();
  const { translate } = useTranslation();
  const route = useRouteRef();
  const { isPortfolioPage } = useRouteChecks(route);

  /**
   * We only call the draft below AFTER we have the risk dates, so that we can guarantee that we are using
   * the correct initial positionDate for a given risk portfolio
   */
  const { data: riskDates } = useGetRiskDates(slug, { enabled: options.enabled });

  /**
   * If positionDate is NOT supplied, then the API will always use the LAST available risk date to create the draft
   *
   * This is ONLY true for risk portfolios.
   */
  const params = computed((): { positionDate?: string; rescalingPage?: CalculateParamConstants } | undefined => {
    if (force) return force;

    if (!positionDate.value) return undefined;
    return {
      rescalingPage: CalculateParamConstants.RISK,
      positionDate: positionDate.value.toFormat(DateFormat.YYYY_MM_DD),
    };
  });

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value && !!params.value),
    computed(() => !!riskDates.value?.length),
    isPortfolioPage,
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.draft(slug, params),
      queryFn: () => getPortfolioTreeNewDraft(unwrap(slug), unwrap(params)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
      onError(err) {
        const error = parseResponseError(err);
        let failReason;
        if (error === KnownError.NetworkError) {
          failReason = PortfolioLoadingFailReason.NetworkError;
        } else if (error === ErrorCodeConstants.PORTFOLIO_NOT_FOUND) {
          failReason = PortfolioLoadingFailReason.NotFound;
        } else {
          failReason = PortfolioLoadingFailReason.Other;
        }

        if (
          axios.isAxiosError(err) &&
          err.response?.data &&
          err.response.data.message === 'Specified position date not found'
        ) {
          const { errorToast } = useToasts();
          const { translate } = useTranslation();
          errorToast(translate({ path: 'GLOBAL.ERROR.POSITION_DATE_NOT_FOUND' }));
        }

        PortfolioModule.SetPortfolioTreeFailReason(failReason);
        console.error(translate({ path: 'ERROR.FETCH_PORTFOLIO_TREE' }));
      },
    }),
    enabled,
  );
}

export const useAppendPortfolioTree = defineMutation<IPortfolioTree, Error, AppendPortfolioTreeInput>({
  mutationFn({ slug, payload }: AppendPortfolioTreeInput) {
    if (!slug) {
      return Promise.reject(new Error('Unexpected empty slug'));
    }
    return appendPortfolioTree(slug, payload);
  },
  invalidateCache(client, data) {
    const selectors: (readonly Record<string, unknown>[])[] = [
      // when invalidating, we probably want to invalidate all portfolio tree data
      keys.all(),
      // Also invalidating non-tree portfolios to make sure data is consistent
      [{ slug: data.slug }],
      [{ payload: data.payload }],
    ];
    return Promise.all([
      client.invalidateQueries(discoveryAllKey()),
      ...selectors.map((selector) => client.invalidateQueries(selector)),
    ]);
  },
});

export function usePortfolioKdeData(
  slug: Ref<string | null | undefined>,
  query: Ref<IPortfolioKdeQuery | null>,
  options: VQQueryOptions<IKdeResponseDto> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );

  return usePremialabQuery(
    useQuery({
      queryKey: keys.kde(slug, query),
      queryFn: () => getPortfolioTreeKDE(unwrap(slug), unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}
