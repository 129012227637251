<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-row
      v-if="isMaster"
      class="mt-1 mb-3"
    >
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.TYPE' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown
            :disabled="disabled || !canEditPortfolioType"
            :menu-class="{ 'scroll-md': true, shadow: true }"
          >
            <template #button-content>
              <span :style="isBenchmark ? benchmarkColor : ''">
                {{ translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: masterPortfolioTreeType }) }}
              </span>
            </template>
            <b-dropdown-item
              v-for="option of portfolioTypes"
              :key="option"
              :active="masterPortfolioTreeType === option"
              @click.stop="changeMasterPortfolioType(option)"
            >
              {{ translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: option }) }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col class="d-flex flex-column text-right font-size-sm">
        <template v-if="showCreatedAt">
          <span
            v-if="isMaster && createdBy && createdAt"
            class="text-gray"
            align-h="end"
          >
            {{
              translate({
                path: 'MODALS.STATIC_DATA_MODAL.CREATED_BY',
                params: {
                  who: createdBy,
                  when: createdAt,
                },
              })
            }}
          </span>
          <span
            v-if="isMaster && updatedBy && updatedAt"
            class="text-gray"
            align-h="end"
          >
            {{
              translate({
                path: 'MODALS.STATIC_DATA_MODAL.UPDATED_BY',
                params: {
                  who: updatedBy,
                  when: updatedAt,
                },
              })
            }}
          </span>
        </template>
      </b-col>
    </b-row>

    <b-row
      v-if="isMaster || isPointerPortfolio"
      class="mt-1 mb-3"
    >
      <b-col>
        <b-form-group
          v-if="isBasket"
          :label="translate({ path: 'GLOBAL.TICKER' })"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
          class="no-padding-left mb-0"
        >
          <b-form-input
            id="bbg-ticker-input"
            v-model="subportfolio.reference"
            :placeholder="translate({ path: 'MODALS.STATIC_DATA_MODAL.TICKER_INPUT' })"
          />
        </b-form-group>
        <b-form-group
          v-else
          :label="translate({ path: 'GLOBAL.PORTFOLIO_INDEX' })"
          class="no-padding-left mb-0"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
        >
          <IndexSearchBoxSelect
            v-model="subportfolio.reference"
            :disabled="disabled"
            :dsb-options="dsbOptionsPortfolioIndex"
            :disabled-codes.sync="disabledReferenceCodes"
            :should-show-code-in-button="true"
            timer-id="StaticDataModal reference DSB"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row
      v-if="isMaster || isPointerPortfolio"
      class="mb-3"
    >
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.BENCHMARK' })"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
          class="no-padding-left mb-0"
        >
          <IndexSearchBoxSelect
            v-model="subportfolio.benchmark"
            :disabled="disabled || (isMaster && isBenchmark)"
            :button-content-override="isMaster && isBenchmark ? '-' : undefined"
            :dsb-options="dsbOptionsBenchmark"
            timer-id="StaticDataModal benchmark DSB"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.CURRENCY.TITLE' })"
          class="mb-0"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
        >
          <StandardFormDropdown
            v-if="isMaster"
            :menu-class="{ 'scroll-md w-100': true, shadow: true }"
            :disabled="disabled"
          >
            <template #button-content>
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: masterPortfolio.portfolioTree.toCurrency }) }}
            </template>
            <CurrencyDropdownItems>
              <template #default="{ currency }">
                <b-dropdown-item
                  :key="currency"
                  :active="masterPortfolio.portfolioTree.toCurrency === currency"
                  @click.stop="changeMasterPortfolioCurrency(currency)"
                >
                  {{ currency }}
                </b-dropdown-item>
              </template>
            </CurrencyDropdownItems>
          </StandardFormDropdown>
          <b-form-input
            v-else
            v-model="subportfolio.toCurrency"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.FX_CONVERSION.FX_CONVERSION' })"
          class="mb-0"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
        >
          <StandardFormDropdown
            v-if="isMaster"
            :disabled="isFxConversionDisabled"
          >
            <template #button-content>
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: masterPortfolio.portfolioTree.fxType }) }}
            </template>
            <b-dropdown-item
              v-for="option of fxConversionTypes"
              :key="option"
              :active="masterPortfolio.portfolioTree.fxType === option"
              @click.stop="changeMasterPortfolioFxType(option)"
            >
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: option }) }}
            </b-dropdown-item>
          </StandardFormDropdown>
          <b-form-input
            v-else
            v-model="inheritedType"
            :title="inheritedType"
            class="text-ellipsis"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <hr />
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.ASSET_CLASS' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled || isBasket">
            <template #button-content>
              {{
                subportfolio.assetClass
                  ? translate({ path: 'GLOBAL.ASSET_CLASS_CONSTANTS', item: subportfolio.assetClass })
                  : translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' })
              }}
            </template>
            <b-dropdown-item
              v-for="option of assetClassTypes"
              :key="option.value"
              :active="subportfolio.assetClass === option.value"
              @click.stop="subportfolio.assetClass = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.REGION' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled">
            <template #button-content>
              {{
                subportfolio.region
                  ? translate({ path: 'GLOBAL.REGION_CONSTANTS', item: subportfolio.region })
                  : translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' })
              }}
            </template>
            <b-dropdown-item
              v-for="option of regionTypes"
              :key="option.value"
              :active="subportfolio.region === option.value"
              @click.stop="subportfolio.region = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col cols="6">
        <b-form-group
          :label="translate({ path: 'GLOBAL.FACTOR' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled">
            <template #button-content>
              {{
                subportfolio.factor
                  ? translate({ path: 'GLOBAL.FACTOR_CONSTANTS', item: subportfolio.factor })
                  : 'Select one...'
              }}
            </template>
            <b-dropdown-item
              v-for="option of factorTypes"
              :key="option.value"
              :active="subportfolio.factor === option.value"
              @click.stop="subportfolio.factor = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        class="mb-3"
      >
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.STYLE' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled || isBasket">
            <template #button-content>
              {{
                subportfolio.style
                  ? translate({ path: 'GLOBAL.STYLE_CONSTANTS', item: subportfolio.style })
                  : translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' })
              }}
            </template>
            <b-dropdown-item
              v-for="option of styleTypes"
              :key="option.value"
              :active="subportfolio.style === option.value"
              @click.stop="subportfolio.style = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <!-- Show the custom inception date for all root components -->
      <b-col
        v-if="isMaster || isPointerPortfolio"
        cols="6"
      >
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.INCEPTION_DATE' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <span class="d-flex">
            <StandardFormDropdown
              id="custom-inception-date"
              ref="dateDropdown"
              data-testid="custom-inception-date"
              :disabled="disabled"
              class="w-100"
              @hidden="onHidden"
            >
              <template #button-content>
                {{ subportfolio.customInceptionDate ?? translate({ path: 'MODALS.STATIC_DATA_MODAL.SELECT_DATE' }) }}
              </template>
              <InceptionDateCalendar
                :event-bus="eventBus"
                :default-date="subportfolio.customInceptionDate"
                :should-allow-future-days="isBasket"
                @close-dropdown="closeDropdown"
                @new-date="onNewCustomInceptionDate"
              />
            </StandardFormDropdown>
            <StandardButton
              v-if="subportfolio.customInceptionDate"
              :disabled="disabled"
              :icon="'trash-alt'"
              variant="danger"
              class="ml-2"
              @click="removeCustomInceptionDate"
            />
          </span>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <hr />
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col>
        <b-form-group
          :label="translate({ path: 'MODALS.PROXY_EDIT_ATTRIBUTE_MODAL.PROPERTIES.DESCRIPTION' })"
          :disabled="disabled"
          label-class="pr-3"
          label-align="bottom"
          class="mb-0 no-padding-left"
        >
          <b-form-textarea
            v-model.lazy="subportfolio.description"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { del, set } from 'vue-demi';
import { FactorConstants } from '@/constants/FactorConstants';
import { Region } from '@/constants/Region';
import { StyleConstants } from '@/constants/StyleConstants';
import { ATTRIBUTE_DATABASE_NAME } from '@/types/strategy';
import {
  IPortfolioTree,
  IPortfolioTreeSubportfolio,
  ISetPortfolioTreeParams,
  isPortfolioTreeValid,
} from '@/types/IPortfolioTree';
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { FxConversion } from '@/constants/FxConversion';
import { translatorForProperty } from '@/utils/strategy';
import useTranslation from '@/composables/useTranslation';
import CurrencyDropdownItems from '@/components/CurrencyDropdownItems.vue';
import useFxConversion from '@/composables/useFxConversion';
import { DataTypes } from '@/types/setting';
import { DSBOptions } from '@/types/DSBOptions';
import { DateFormat, InputDateFormat } from '@/constants/DateFormat';
import { useListTeamMembers } from '@/composables/queries/useUserQueries';
import { ITeamUser } from '@/api-v2/web/user';
import { createDate } from '@/utils/dateUtils';
import { Currency } from '@/constants/Currency';
import {
  createClientSideEmptyBasket,
  createClientSideEmptyPortfolio,
  isConstituentPortfolioFn,
} from '@/utils/portfolioTree';
import mitt from 'mitt';
import StandardFormDropdown from '@/components/standard-components/StandardFormDropdown.vue';
import InceptionDateCalendar from './InceptionDateCalendar.vue';
import IndexSearchBoxSelect from '@/components/index-search-box/IndexSearchBoxSelect.vue';
import { useAllPortfolios } from '@/composables/queries/useDataDiscovery';
import { notNull } from '@/utils/notnull';
import { PortfolioType } from '@/types/portfolio';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { useVModel } from '@vueuse/core';
import { SettingsModule } from '@/store/modules/settings';

function formatUserName(user: ITeamUser): string {
  const { firstname, lastname, email } = user;
  return firstname === '' || lastname === '' ? email : `${firstname} ${lastname}`;
}

export default defineComponent({
  name: 'PortfolioStaticData',
  components: {
    CurrencyDropdownItems,
    InceptionDateCalendar,
    IndexSearchBoxSelect,
  },
  props: {
    masterPortfolioObj: {
      type: Object as PropType<IPortfolioTree | ISetPortfolioTreeParams>,
      required: true,
    },
    subportfolioObj: {
      type: Object as PropType<IPortfolioTreeSubportfolio>,
      required: true,
    },
    isVueQueryEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Is the given portfolio tree a master portfolio?
     */
    isMaster: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If true, disable all inputs in this component
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inheritedFrom: {
      type: String,
      required: false,
    },
    /**
     * Prevent the createdAt and updatedAt to show on create new and duplicate portfolio
     */
    showCreatedAt: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();
    const { changeFxConversionParam } = useFxConversion();

    const masterPortfolio = useVModel(props, 'masterPortfolioObj', emit, { passive: true });
    const subportfolio = useVModel(props, 'subportfolioObj', emit, { passive: true });

    const masterPortfolioTreeType = computed({
      get(): DataTypes {
        if (masterPortfolio.value.isBenchmark) return DataTypes.BENCHMARK;
        if (masterPortfolio.value.portfolioTree.type === PortfolioType.EQUITY_BASKET) return DataTypes.EQUITY_BASKET;
        if (masterPortfolio.value.portfolioTree.type === PortfolioType.QIS_BASKET) return DataTypes.QIS_BASKET;
        return DataTypes.PORTFOLIO;
      },
      set(newVal: DataTypes) {
        set(masterPortfolio.value, 'isBenchmark', newVal === DataTypes.BENCHMARK);

        switch (newVal) {
          case DataTypes.EQUITY_BASKET:
            set(masterPortfolio.value.portfolioTree, 'type', PortfolioType.EQUITY_BASKET);
            break;

          case DataTypes.QIS_BASKET:
            set(masterPortfolio.value.portfolioTree, 'type', PortfolioType.QIS_BASKET);
            break;

          default:
            set(masterPortfolio.value.portfolioTree, 'type', PortfolioType.PORTFOLIO);
        }
      },
    });

    const isConstituentPortfolio = isConstituentPortfolioFn(
      computed(() => {
        if (masterPortfolio.value) {
          if (isPortfolioTreeValid(masterPortfolio.value)) return null;
          return masterPortfolio.value;
        }
        return null;
      }),
    );
    const isFxConversionDisabled = computed(() => {
      if (isBasket.value) return true;

      return props.disabled || isConstituentPortfolio.value;
    });

    // no need to sort these because the portfolios are never shown
    const portfolioTrees = useAllPortfolios();
    const eventBus = mitt<Record<'on-hidden', void>>();
    const dateDropdown = ref<InstanceType<typeof StandardFormDropdown> | null>(null);

    const assetClassTypes = Object.values(AssetClassConstants).map(
      translatorForProperty(ATTRIBUTE_DATABASE_NAME.AssetClass),
    );

    const dataTypeColors = computed(() => SettingsModule.colors.productType || {});

    const benchmarkColor = computed(() => `color:${dataTypeColors.value[DataTypes.BENCHMARK]};`);

    const factorTypes = Object.values(FactorConstants).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Factor));

    const regionTypes = Object.values(Region).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Region));

    const styleTypes = Object.values(StyleConstants).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Style));

    const fxConversionTypes = Object.values(FxConversion);

    const teamMembers = useListTeamMembers({
      enabled: computed(() => props.isVueQueryEnabled && props.isMaster),
    });

    const { hasEquityBasketAccess, hasQISBasketAccess } = useFeatureFlag();

    /**
     * If the user has access to equity basket AND the portfolio has not yet been created,
     * only then show Equity Basket as an option - users may not convert existing portfolios
     * into Equity Baskets
     */
    const portfolioTypes = computed((): DataTypes[] => {
      const retval: DataTypes[] = [DataTypes.PORTFOLIO, DataTypes.BENCHMARK];

      if (isPortfolioTreeValid(masterPortfolio.value)) {
        if (hasEquityBasketAccess.value) retval.push(DataTypes.EQUITY_BASKET);

        if (hasQISBasketAccess.value) retval.push(DataTypes.QIS_BASKET);
      }

      return retval;
    });

    const dsbOptionsBenchmark: DSBOptions = {
      selectMultiple: false,
    };

    const dsbOptionsPortfolioIndex: DSBOptions = {
      selectMultiple: false,
    };

    const inheritedType = computed(
      () =>
        `${translate({ path: 'MODALS.STATIC_DATA_MODAL.INHERITED' })} ${translate({ path: 'MODALS.GENERAL.FROM' })} ${
          props.inheritedFrom
        } (${translate({
          path: 'GLOBAL.FX_CONVERSION',
          item: masterPortfolio.value.portfolioTree.fxType,
        })})`,
    );

    const isBasket = computed(() =>
      [DataTypes.EQUITY_BASKET, DataTypes.QIS_BASKET].includes(masterPortfolioTreeType.value),
    );

    const isBenchmark = computed(() => masterPortfolioTreeType.value === DataTypes.BENCHMARK);

    /**
     * List of codes already used as a reference
     * Should be disabled in the DSB
     */
    const disabledReferenceCodes = computed(() =>
      (portfolioTrees.data.value ?? []).map((x) => x.reference).filter(notNull),
    );

    const createdBy = computed(() => {
      if (!masterPortfolio.value || isPortfolioTreeValid(masterPortfolio.value)) {
        return;
      }

      if (masterPortfolio.value.createdBy === 0) {
        return 'Premialab'; // Auto-generated sample portfolios may have createdBy === 0
      }

      const match = teamMembers.data.value?.get(masterPortfolio.value.createdBy);
      if (!match) {
        return null;
      }

      return formatUserName(match);
    });

    const createdAt = computed(() => {
      if (!masterPortfolio.value || isPortfolioTreeValid(masterPortfolio.value)) {
        return null;
      }

      const date = createDate(masterPortfolio.value.createdAt, {
        format: InputDateFormat.ISO8601,
        allowNonBusiness: true,
      });

      return date.toFormat(DateFormat.D_MMM_YYYY);
    });

    const updatedBy = computed(() => {
      if (!masterPortfolio.value || isPortfolioTreeValid(masterPortfolio.value)) {
        return;
      }

      const match = teamMembers.data.value?.get(masterPortfolio.value.updatedBy);
      if (!match) {
        return null;
      }

      return formatUserName(match);
    });

    const updatedAt = computed(() => {
      if (!masterPortfolio.value || isPortfolioTreeValid(masterPortfolio.value)) {
        return null;
      }

      const date = createDate(masterPortfolio.value.updatedAt, {
        format: InputDateFormat.ISO8601,
        allowNonBusiness: true,
      });

      return date.toFormat(DateFormat.D_MMM_YYYY);
    });

    const changeMasterPortfolioCurrency = (toCurrency: Currency) => {
      if (!masterPortfolio.value) return;

      set(masterPortfolio.value.portfolioTree, 'toCurrency', toCurrency);
      changeFxConversionParam(masterPortfolio.value.portfolioTree, {
        toCurrency,
        fxType: masterPortfolio.value.portfolioTree.fxType,
      });
    };

    const changeMasterPortfolioFxType = (fxType: FxConversion) => {
      if (!masterPortfolio.value) return;

      set(masterPortfolio.value.portfolioTree, 'fxType', fxType);

      changeFxConversionParam(masterPortfolio.value.portfolioTree, {
        toCurrency: masterPortfolio.value.portfolioTree.toCurrency,
        fxType,
      });
    };

    /**
     * Reset the calendar when it is hidden
     */
    const onHidden = () => {
      eventBus.emit('on-hidden');
    };

    /**
     * Allows for the dropdown to close properly
     */
    const closeDropdown = () => {
      if (dateDropdown.value) dateDropdown.value.hide();
    };

    /**
     * new custom inception date chosen from the calendar
     */
    const onNewCustomInceptionDate = (newDate: string | undefined) => {
      set(subportfolio.value, 'customInceptionDate', newDate);
    };

    const removeCustomInceptionDate = () => {
      del(subportfolio.value, 'customInceptionDate');
    };

    /**
     * Users can only edit the portfolio type if the portfolio has not been created, OR
     * if the type is not equity basket
     */
    const canEditPortfolioType = computed(() => {
      if (isPortfolioTreeValid(masterPortfolio.value)) return true;
      return isPortfolioTreeValid(masterPortfolio.value) || !isBasket.value;
    });

    /**
     * This is ONLY enabled on the CreateEmptyPortfolioModal,
     * so when we change the type, we change all the items in the form
     * to the defaults for the type
     */
    const changeMasterPortfolioType = (newPortfolioType: DataTypes) => {
      let newEmptyEntity: ISetPortfolioTreeParams | undefined;

      switch (newPortfolioType) {
        case DataTypes.EQUITY_BASKET:
          newEmptyEntity = createClientSideEmptyBasket(
            masterPortfolio.value.portfolioTree.name,
            PortfolioType.EQUITY_BASKET,
          );
          break;

        case DataTypes.QIS_BASKET:
          newEmptyEntity = createClientSideEmptyBasket(
            masterPortfolio.value.portfolioTree.name,
            PortfolioType.QIS_BASKET,
          );
          break;

        default:
          newEmptyEntity = createClientSideEmptyPortfolio(masterPortfolio.value.portfolioTree.name);
      }

      masterPortfolio.value = {
        ...masterPortfolio.value,
        ...newEmptyEntity,
      };
      subportfolio.value = newEmptyEntity.portfolioTree;
      masterPortfolioTreeType.value = newPortfolioType;
    };

    const isPointerPortfolio = computed(() => !!subportfolio.value.pointerSlug);

    return {
      DataTypes,
      translate,
      assetClassTypes,
      factorTypes,
      regionTypes,
      styleTypes,
      fxConversionTypes,
      FxConversion,
      portfolioTypes,
      inheritedType,
      isBenchmark,
      changeMasterPortfolioCurrency,
      changeMasterPortfolioFxType,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      benchmarkColor,
      isFxConversionDisabled,
      disabledReferenceCodes,

      onHidden,
      eventBus,
      closeDropdown,
      dateDropdown,
      onNewCustomInceptionDate,
      removeCustomInceptionDate,

      dsbOptionsPortfolioIndex,
      dsbOptionsBenchmark,

      PortfolioType,
      isBasket,
      canEditPortfolioType,

      masterPortfolio,
      masterPortfolioTreeType,

      subportfolio,
      changeMasterPortfolioType,
      isPointerPortfolio,
    };
  },
});
</script>
<style scoped>
.no-padding-left :deep(.form-row .col) {
  padding-left: 0;
}
</style>
